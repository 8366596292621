import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [];

  static values = {
    fractionSize: Number,
    relativeElement: String,
  }

  connect() {
    this.relativeElement = document.getElementById(this.relativeElementValue)

    if(!this.relativeElement) {
      if(this.hasRelativeElementValue) { // element not found because dom is not fully loaded yet
        window.addEventListener("load", () => {
          this.relativeElement = document.getElementById(this.relativeElementValue);
          if (this.relativeElement) this.update();
          window.removeEventListener("load", this);
        })
      }
    } else {
      this.update();
    }

  }
  update() {
    this.updateWidth();
    this.updateHeight();
  }

  updateWidth() {
    if (!this.relativeElement) return;

    const parentBounds = this.relativeElement.getBoundingClientRect()
    const parentWidth = this.relativeElement.clientWidth // excluding borders
    const parentInset = (parentBounds.width - parentWidth) /2
    const parentFractionCount = Math.floor(parentWidth / this.fractionSizeValue)
    const newFractionSize = parentWidth / parentFractionCount
    // calculate width relative to background dots
    const bounds = this.element.getBoundingClientRect()
    const elementFractionCount = Math.ceil(bounds.width / newFractionSize)
    const newWidth = elementFractionCount * newFractionSize
    this.element.style.setProperty("--background-width", `${newWidth}px`)
    // calculate position relative to background dots
    const relativeLeft = bounds.left - parentBounds.left - parentInset
    const horizontalCenter = relativeLeft + (bounds.width/2 - newWidth/2)
    const newHorizontalCenter = Math.round(horizontalCenter / newFractionSize) * newFractionSize
    const xOffset = newHorizontalCenter - horizontalCenter
    this.element.style.setProperty("--background-offset-x", `${xOffset}px`)
  }

  updateHeight() {
    if (!this.relativeElement) return;

    const parentBounds = this.relativeElement.getBoundingClientRect()
    const parentHeight = this.relativeElement.clientHeight // excluding borders
    const parentInset = (parentBounds.height - parentHeight) /2
    const parentFractionCount = Math.floor(parentHeight / this.fractionSizeValue)
    const newFractionSize = parentHeight / parentFractionCount
    // calculate height relative to background dots
    const bounds = this.element.getBoundingClientRect()
    const elementFractionCount = Math.ceil(bounds.height / newFractionSize)
    const newHeight = elementFractionCount * newFractionSize
    this.element.style.setProperty("--background-height", `${newHeight}px`)
    // calculate position relative to background dots
    const relativeTop = bounds.top - parentBounds.top - parentInset
    const verticalCenter = relativeTop + (bounds.height/2 - newHeight/2)
    const newVerticalCenter = Math.round(verticalCenter / newFractionSize) * newFractionSize
    const yOffset = newVerticalCenter - verticalCenter
    this.element.style.setProperty("--background-offset-y", `${yOffset}px`)
  }

  getTranslateX(element) {
    var style = window.getComputedStyle(element);
    var matrix = new WebKitCSSMatrix(style.transform);
    return matrix.m41;
  }
}