import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    // dynamic import (mapbox-gl will only be fetched from the server when needed)
    return import(/* webpackChunkName: "mapbox-gl" */ '!mapbox-gl').then(({ default: mapboxgl }) => {

      mapboxgl.accessToken = 'pk.eyJ1IjoiZm9ybXJhdXNjaCIsImEiOiJja3h6dzBmZmUyc3JrMnBvMGtkenVqcjY5In0.Ob4f1AhJiYCjL9ZCQMEJ7Q';
      var map = new mapboxgl.Map({
          container: this.element,
          style: 'mapbox://styles/mapbox/streets-v9?optimize=true',
          center: [7.594602086228619, 50.35739029626913],
          zoom: 16,
          cooperativeGestures: true // prevent drag / scroll conflict
      });

      var nav = new mapboxgl.NavigationControl();
      map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

      map.scrollZoom.disable();

      var el = document.createElement('div');
      el.className = 'marker';

      new mapboxgl.Marker(el)
        .setLngLat([7.594602086228619, 50.35739029626913])
        .addTo(map);
    });
  }

  disconnect() {

  }
}