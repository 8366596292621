import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
  }

  focus() {
    if( this.element == document.activeElement) {
      this.element.click(); // enter link
      //location.href = this.element.href;
    } else {
      this.element.focus(); // focus to show hover
    }
  }

  blockAction(e) {
    e.preventDefault();
  }

  disconnect() {

  }
}