import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [];

  scrollListener;
  observer;
  side;
  depth;


  connect() {
    this.observer = new IntersectionObserver(this.onIntersection.bind(this), {});
    this.observer.observe(this.element);

    this.side = this.element.getBoundingClientRect().left < window.innerWidth/2 ?
      1 : -1;

    this.depth = Math.floor(Math.random() * Math.floor(3)) *15;
  }

  onIntersection(entries) {
    const entry = entries[0];

    if (entry.intersectionRatio > 0) { // In

      this.scrollListener = this.onScroll.bind(this);
      document.addEventListener('scroll', this.scrollListener, { //wheel
        capture: false, passive: true
      });

    } else { // Out
      document.removeEventListener("scroll", this.scrollListener);
    }
  }

  onScroll(evt) {
    let bounds = this.element.getBoundingClientRect();
    let positionY =  bounds.top + window.scrollY;
    let y = positionY <= window.innerHeight ?
      window.scrollY : window.innerHeight - bounds.top;


    let transform = Math.round( y / (16 + this.depth) ) * this.side; // /16

    this.element.style.transform = "translateX("+ transform +"px)";
    //this.element.style.transform = "scaleX("+ ((transform*this.side)+bounds.width)/bounds.width +")";
  }
}